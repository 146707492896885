@import '../../assets/styles/mixins';

.recommendations-page__container {
  position: relative;
  max-width: 1170px;
  width: 100%;
  padding: 0 20px;
  overflow-x: hidden;

  @media (max-width: 500px) {
    max-width: 100%;
    padding: 0 20px;
  }

  .sliders-wrapper {
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
  }

  .full__logo-wrapper {
    margin-bottom: 40px;

    @media (max-width: 500px) {
      margin-bottom: 40px;
    }
  }

  .back__buttons-wrapper {
    margin-bottom: 40px;

    @media (max-width: 500px) {
      margin-bottom: 32px;
    }
  }

  .recommendations__subtitle {
    @include modalCardTextStyles;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    max-width: 830px;
    width: 100%;
    letter-spacing: 0;
    margin-bottom: 40px;

    @media (max-width: 500px) {
      font-size: 16px;
      line-height: 20px;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  .recommendations {
    margin-bottom: 16px;

    h2 {
      @include modalCardTextStyles;
      font-size: 42px;
      font-weight: 700;
      line-height: 63px;
      width: 100%;
      max-width: 1000px;
      margin-bottom: 20px;

      @media (max-width: 500px) {
        max-width: 100%;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 16px;
      }
    }
  }

  .alternatives {
    h3 {
      @include modalCardTextStyles;
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
      width: 100%;
      max-width: 1000px;
      margin-bottom: 20px;

      @media (max-width: 500px) {
        max-width: 100%;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 16px;
      }
    }
  }

  .display-none {
    display: none;
  }
}

.btn-wrapper {
  max-width: 1170px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  padding: 20px;

  @media (max-width: 500px) {
    justify-content: center;
  }

  button {
    @include modalCardTextStyles;
    border-radius: 50px;
    padding: 19px 40px;
    background: #e62600;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;

    @media (max-width: 500px) {
      width: 100%;
      max-width: 335px;
      padding: 12px 20px;
      margin-bottom: 0;
    }
  }
}
