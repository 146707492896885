@mixin modalCardTextStyles {
  font-family: Gilroy, serif;
  font-style: normal;
  text-align: left;
  color: #323264;
}

@mixin redButtonWrapperStyles {
  width: 375px;
  margin-top: -16px;
  background: white;
}

@mixin redButtonStyles {
  font-family: Gilroy, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-align: center;
  border-radius: 50px;
  background: #e62600;
  border: solid 2px #e62600;
  color: white;
  padding: 19px 48px;
  cursor: pointer;
}

@mixin mobileRedButtonStyles {
  max-width: 335px;
  width: 100%;
  height: 52px;
  line-height: 27px;
  letter-spacing: 0;
  padding: 12px 20px;
  left: 20px;
  margin-top: 48px;
}

@mixin sliderArrowsStyles {
  content: '';
  width: 10px;
  height: 2px;
  position: absolute;
  border-radius: 1px;
  background-color: #ffffff;
}

@mixin sliderArrowsBackground {
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #323264;
  z-index: 10;
  box-shadow: 0 18px 38px -10px grey;
  cursor: pointer;
}
