@import '../../../assets/styles/mixins';

.label {
  @include modalCardTextStyles;
  border-radius: 12px;
  padding: 1px 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 12px;
  margin-right: 10px;
  background-color: #eaedf5;

  @media (max-width: 500px) {
    margin-left: 0;
  }
}
