@import '../../../../assets/styles/mixins';

.quiz-button {
  @include modalCardTextStyles;
  border-radius: 100px;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  margin: 0 10px 10px 0;
  text-align: center;
  background: #eaedf5;
  cursor: pointer;

  &--selected,
  &:hover {
    background: #323264;
    color: white;
  }

  @media (max-width: 500px) {
    padding: 17px 20px;

    &:hover,
    &:focus {
      background: #eaedf5;
      color: #323264;
    }

    &--selected {
      background: #323264 !important;
      color: white !important;
    }
  }
}
