@import '../../../assets/styles/mixins';

.modal {
  &__card-wrapper {
    max-width: 1170px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: -40px;
    z-index: 50;
    margin-top: 40px;
    margin-left: -20px;

    @media (max-width: 375px) {
      width: 375px;
      flex-direction: column;
    }
  }

  &__transparent-half {
    max-width: 560px;
    width: 100%;
    height: 100%;
    background: grey;
    opacity: 0.5;
    z-index: 10;

    @media (max-width: 500px) {
      position: absolute;
      height: 73px;
      top: 0;
    }
  }

  &__card-window {
    max-width: 620px;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 5;

    @media (max-width: 500px) {
      width: 100%;
      margin-top: 73px;
    }
  }

  &__card-content {
    max-width: 620px;
    width: 100%;
    max-height: 908px;
  }

  &__close-cross {
    position: relative;
  }

  &__card-image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (max-width: 500px) {
      width: 120px;
      height: 120px;
      margin: 0;
    }
  }

  &__main-content {
    overflow-y: scroll;
    background-color: #ffffff;
    height: 1082px;

    @media (max-width: 375px) {
      height: 100%;
      max-width: 100%;
      width: 100%;
    }
  }

  &__primary-content {
    display: flex;
    margin: 64px 32px 16px 12px;

    @media (max-width: 500px) {
      flex-direction: column;
      padding: 72px 20px 12px 20px;
      margin: 0;
    }

    .modal__card-image {
      width: 240px;
      height: 240px;

      @media (max-width: 500px) {
        width: 200px;
        height: 200px;
        margin: 0 auto 24px;
      }
    }

    .primary-content {
      &__text {
        margin-left: 20px;

        @media (max-width: 500px) {
          margin-left: 0;
        }
      }

      &__title {
        @include modalCardTextStyles;
        font-size: 26px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0.2px;
        margin-bottom: 12px;

        @media (max-width: 375px) {
          font-size: 18px;
          line-height: 27px;
          //max-width: 199px;
          width: 100%;
        }
      }

      &__subtitle {
        @include modalCardTextStyles;
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #000000;
        margin-right: 10px;
        margin-bottom: 12px;
      }

      &__label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }

  &__secondary-content {
    width: 100%;
    padding: 16px 32px 32px 32px;

    @media (max-width: 500px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 120px;
      width: 100%;
    }

    .description-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      @media (max-width: 500px) {
        &:last-child {
          margin-bottom: 0;
        }
      }

      &__title {
        @include modalCardTextStyles;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 8px;
      }

      &__description {
        @include modalCardTextStyles;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  &__image-rating__stars {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;

    @media (max-width: 375px) {
      margin-top: -2px;
    }
  }

  &__image-rating__mark {
    @include modalCardTextStyles;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: -1px 0 0 24px;
  }

  @media (max-width: 375px) {
    .modal__mark-average {
      display: none;
    }
  }
}

.close-cross {
  position: absolute;
  top: 22px;
  left: 22px;
  cursor: pointer;

  @media (max-width: 375px) {
    top: 20px;
  }
}

.btn__label {
  @include modalCardTextStyles;
  border-radius: 12px;
  padding: 1px 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 12px;
  background-color: #eaedf5;

  @media (max-width: 500px) {
    margin-left: 0;
  }
}

.modal-card__image-rating {
  display: flex;
  margin: 0 0 0 16px;
}
