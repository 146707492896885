@import '../../../assets/styles/mixins';

.back__buttons-wrapper {
  max-width: fit-content;
  display: flex;
  margin-bottom: 40px;

  &:hover {
    cursor: pointer;
  }

  .back__button-text {
    @include modalCardTextStyles;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    display: flex;
    align-items: flex-end;
    margin-left: 8px;
  }
}

@media (max-width: 500px) {
  .back__buttons-wrapper {
    margin-bottom: 32px;
  }
}
