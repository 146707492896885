@import '../../../assets/styles/mixins';

.productCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #c4c4c4;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 400px;

  @media (max-width: 375px) {
    max-width: 150px;
    padding: 10px;
    min-height: 400px;
  }

  .wrapper {
    position: relative;
    img {
      width: 85%;
      height: 100px;
      object-fit: contain;
      user-select: none;
      padding-bottom: 10px;
      border-bottom: solid 1px #ededed;
      margin-bottom: 12px;
    }

    //  HOLD - UNUSED STYLES
    //  .btn__card-category {
    //  @include modalCardTextStyles;
    //  height: 20px;
    //  border-radius: 12px;
    //  padding: 2px 8px;
    //  font-size: 12px;
    //  font-weight: 600;
    //  line-height: 18px;
    //
    //  @media (max-width: 500px) {
    //    margin-left: 0;
    //  }
    //}

    .btn__label {
      @include modalCardTextStyles;
      height: 20px;
      border-radius: 12px;
      padding: 2px 8px;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      margin-right: 10px;
      margin-bottom: 12px;
    }
  }

  &__checkboxes {
    position: absolute;
    top: 0;
    left: 207px;

    @media (max-width: 450px) {
      left: 120px;
    }

  }

  &__title {
    @include modalCardTextStyles;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 5px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    @media (max-width: 500px) {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }
  }

  &__subtitle {
    @include modalCardTextStyles;
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #000000;
    margin-right: 10px;
    margin-bottom: 12px;

    @media (max-width: 500px) {
      display: inline-block;
      margin-right: 0;
    }
  }

  &__link {
    @include modalCardTextStyles;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-decoration: underline;
    color: #2f4c96;
    cursor: pointer;
    max-width: fit-content;
  }
}

.isChecked {
  border-color: #323264;
}
