@import '../../../assets/styles/mixins';

.recommendations {
  &__slider-wrapper {
    position: relative;
    max-height: 525px;
    margin-bottom: 32px;

    @media (max-width: 375px) {
      margin-bottom: 24px;
    }
  }

  &__card-wrapper {
    display: flex;
    overflow: hidden;
    margin-right: 20px;
    max-width: 1200px;
    width: 100%;
  }
}

.swiper-wrapper {
  display: flex;

  .swiper-slide {
    max-width: 265px;
    margin-right: 20px;

    @media (max-width: 375px) {
      margin-right: 12px;
      width: 100%;
      max-width: 150px;
      min-width: 0;
    }
  }
}

.swiper-button-prev {
  @include sliderArrowsBackground;
  transform: translate(40%, -50%);
}

.swiper-button-prev::after {
  @include sliderArrowsStyles;
  top: 20px;
  left: 20px;
  transform: rotate(135deg);
}

.swiper-button-prev::before {
  @include sliderArrowsStyles;
  top: 27px;
  left: 20px;
  transform: rotate(-135deg);
}

.swiper-button-next {
  @include sliderArrowsBackground;
  transform: translate(-40%, -50%);
  right: 0;

  @media (max-width: 375px) {
    transform: translate(0%, -50%);
  }
}

.swiper-button-next::after {
  @include sliderArrowsStyles;
  top: 20px;
  left: 21px;
  transform: rotate(45deg);
}

.swiper-button-next::before {
  @include sliderArrowsStyles;
  top: 27px;
  left: 21px;
  transform: rotate(-45deg);
}

.swiper-button-disabled {
  display: none;
}
