@import '../../assets/styles/mixins';

.home-page__container {
  position: relative;
  max-width: 1170px;
  width: 100%;
  padding: 0 20px;

  .wrapper {
    margin-bottom: 76px;

    @media (max-width: 500px) {
      margin-bottom: 56px;
    }

    h1 {
      @include modalCardTextStyles;
      max-width: 600px;
      width: 100%;
      font-size: 42px;
      font-weight: 600;
      line-height: 52px;
      letter-spacing: 0.25px;
      margin-bottom: 20px;
      margin-top: 138px;

      @media (max-width: 500px) {
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.05px;
        margin-bottom: 12px;
        margin-top: 68px;
      }
    }

    .home__page-description {
      @include modalCardTextStyles;
      max-width: 600px;
      width: 100%;
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
      margin-bottom: 68px;

      @media (max-width: 500px) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 28px;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 622px;
      z-index: -3;

      @media (max-width: 1169px) {
        display: none;
      }
    }

    .quiz__button {
      margin-bottom: 15px;

      @media (max-width: 500px) {
        max-width: 335px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 46px;
        margin-bottom: 28px;
      }
    }

    .home__page-agreement {
      max-width: 560px;
      width: 100%;
      font-family: Gilroy, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.05px;
      text-align: left;
      color: #323264;

      @media (max-width: 500px) {
        max-width: 100%;
        width: 100%;
      }

      a {
        text-decoration: none;
        color: #323264;
      }

      span {
        margin-left: 4px;
        border-bottom: 1px solid #323264;
      }
    }
  }

  .tool__tip-wrapper {
    max-width: 560px;
    width: 100%;
    font-size: 14px;
    color: #323264;
    font-family: Gilroy, serif;
    line-height: 22px;
    letter-spacing: -0.05px;

    @media (max-width: 500px) {
      max-width: 100%;
    }
  }

  .tool__tip-text {
    margin-bottom: 8px;

    strong {
      margin-right: 4px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
