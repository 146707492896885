@import '../../../assets/styles/mixins';

.quiz__button-wrapper {
  margin-bottom: 14px;

  @media (max-width: 500px) {
    @include redButtonWrapperStyles;
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.next__button-wrapper {
  display: flex;
  align-items: baseline;

  @media (max-width: 500px) {
    flex-direction: column;
    height: auto;
  }

  .next__button {
    @include redButtonStyles;

    @media (max-width: 500px) {
      @include mobileRedButtonStyles;
      margin-top: 0;
    }
  }

  .button-disabled {
    opacity: 0.7;
    color: #ffffff;
    background-color: #88888e;
    border-color: #88888e;
    cursor: default;
  }
}

.quiz__button {
  @include redButtonStyles;

  @media (max-width: 500px) {
    @include mobileRedButtonStyles;
    margin-top: 0;
    height: auto;
  }
}
