/* for input type="checkbox" */
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* label connected with .custom-checkbox */
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

/* label */
.custom-checkbox + label::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: -23px;
  top: 0;
  width: 43px;
  height: 43px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #323264;
  border-radius: 16px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  cursor: pointer;
  @media (max-width: 450px) {
    width: 25px;
    height: 25px ;
    border-radius: 8px;
  }

}

// HOLD - STATE DOESN'T CREATED IN DESIGN
///* checkbox hover state */
//.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
//  border-color: #b3d7ff;
//}

// HOLD - STATE DOESN'T CREATED IN DESIGN
///* checkbox active state */
//.custom-checkbox:not(:disabled):active+label::before {
//  background-color: #b3d7ff;
//  border-color: #b3d7ff;
//}

// HOLD - STATE DOESN'T CREATED IN DESIGN
///* focus */
//.custom-checkbox:focus+label::before {
//  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
//}

// HOLD - STATE DOESN'T CREATED IN DESIGN
///* focus + checked */
//.custom-checkbox:focus:not(:checked)+label::before {
//  border-color: #80bdff;
//}

/* checkbox checked state */
.custom-checkbox:checked + label::before {
  border-color: #323264;
  background-color: #323264;
  background-image: url('../assets/svg/checkmark.svg');
}

// HOLD - STATE DOESN'T CREATED IN DESIGN
///* checkbox disabled state */
//.custom-checkbox:disabled+label::before {
//  background-color: #e9ecef;
//}
