@import '../../../../assets/styles/mixins';

.quiz__question {
  @include modalCardTextStyles;
  max-width: 829px;
  width: 100%;
  font-size: 42px;
  font-weight: 700;
  line-height: 63px;
  margin-bottom: 10px;
  letter-spacing: 0.1px;

  @media (max-width: 500px) {
    max-width: 100%;
    width: 100%;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 12px;
  }
}

.quiz__suggestion {
  @include modalCardTextStyles;
  display: block;
  white-space: pre-line;
  max-width: 582px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;

  @media (max-width: 500px) {
    line-height: 23px;
    max-width: 100%;
    width: 100%;
  }
}
.quiz_sub_info {
  @include modalCardTextStyles;
  display: block;
  white-space: pre-line;
  max-width: 760px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    line-height: 23px;
    max-width: 100%;
    width: 100%;
  }
}

.quiz__answers-wrapper {
  margin: 0 0 106px;

  @media (max-width: 500px) {
    margin-bottom: 36px;
  }
}

.more__question-title {
  @include modalCardTextStyles;
  max-width: 720px;
  width: 100%;
  font-size: 42px;
  font-weight: 700;
  line-height: 63px;
  padding-top: 16px;
  margin: 32px 0 0 120px;
  letter-spacing: 0.3px;

  @media (max-width: 375px) {
    width: 330px;
    font-size: 22px;
    line-height: 28px;
    margin: 20px 0 0 20px;
    letter-spacing: 0.15px;
  }
}

.more__question-subheading {
  @include modalCardTextStyles;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  margin: 22px 0 0 120px;

  @media (max-width: 375px) {
    width: 335px;
    margin: 10px 0 0 20px;
  }
}

.wallgreen__bottle-wrapper {
  position: relative;

  @media (max-width: 1169px) {
    display: none;
  }
}

.wallgreen__bottle-more {
  position: absolute;
  top: -570px;
  left: 622px;
  z-index: -3;

  @media (max-width: 1169px) {
    display: none;
  }
}

.more__question-buttons__wrapper {
  max-width: 620px;
  width: 100%;
  margin: 0;

  @media (max-width: 500px) {
    max-width: 375px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.continue__button {
  min-width: 298px;
  border-radius: 50px;
  background: #e62600;
  margin-right: 10px;
  font-family: Gilroy, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #ffffff;
  padding: 19px 40px;
  cursor: pointer;

  @media (max-width: 500px) {
    height: 52px;
    max-width: 335px;
    min-width: 280px;
    width: 100%;
    line-height: 27px;
    letter-spacing: 0;
    margin-bottom: 12px;
    padding: 10px 12px 12px;
  }
}

.recommendations__button {
  border-radius: 50px;
  font-family: Gilroy, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-align: center;
  padding: 19px 40px;
  min-width: 282px;
  background: white;
  color: #323264;
  border: solid 2px #323264;
  cursor: pointer;
  margin-right: 10px;

  @media (max-width: 500px) {
    height: 52px;
    max-width: 335px;
    min-width: 280px;
    width: 100%;
    line-height: 27px;
    letter-spacing: 0;
    margin-bottom: 12px;
    padding: 10px 12px 12px;
    margin-right: 0;
  }
}
