.full__logo-wrapper {
  width: 100%;
  margin-top: 40px;

  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .header__logo-img {
    margin-top: -2px;
    width: 144px;
  }
}

.header__logo-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;

  img {
    margin-bottom: 2px;
  }
}

.logo__text-wrapper {
  max-width: 172px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 500px) {
    justify-content: center;
    align-items: center;
  }
}

.logo__text-powered {
  font-family: Inter, serif;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  text-align: right;
  color: #383942;

  @media (max-width: 500px) {
    font-size: 9px;
    line-height: 14px;
    margin-left: 0;
  }
}

.logo__text-symbol {
  @media (max-width: 500px) {
    width: 7px;
    height: 7px;
    margin-left: 6px;
  }
}

.logo__text-optima {
  @media (max-width: 500px) {
    margin-left: 4px;
    width: 66px;
  }
}
