@import '../../../../assets/styles/mixins';

.question-page__container {
  width: 100%;
  padding: 0 20px;
  max-width: 1130px;

  @media (max-width: 500px) {
    max-width: 375px;
    padding: 0 20px 56px;
  }

  .full__logo-wrapper {
    margin-bottom: 36px;
  }

  .next__button-wrapper {
    .quiz-button {
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 375px) {
    .quiz__answer {
      height: 52px;
      width: 96px;
      border-radius: 100px;
      padding: 16px 20px;
      margin: 12px 10px 0 0;
    }
  }
}
