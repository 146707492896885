.progress__bar {
  height: 8px;
  border-radius: 8px;
  background: #c4c4c44d;
  max-width: 1130px;
  width: 100%;
  margin-left: 0;
  margin-bottom: 24px;

  &-step {
    height: 8px;
    left: 120px;
    top: 136px;
    border-radius: 8px;
    background: #323264;
  }
}

@media (max-width: 500px) {
  .progress__bar {
    height: 4px;
    border-radius: 4px;

    &-step {
      height: 4px;
      width: 71.5px;
      border-radius: 4px;
    }
  }
}
